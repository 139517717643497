import ApiService from './api.service';

const BookingService = {
    getBookings: async function(before = null, after = null, user = null) {
        try {
            let userQueryParam = '';

            if (user) {
                userQueryParam = `&email=${user}`;
            }

            const response = await ApiService.get(`bookings?bookedForDate[before]=${before}&bookedForDate[after]=${after}${userQueryParam}`);

            return response.json();
        } catch (err) {
            return { error: err };
        }
    },

    postBooking: async function(data) {
        try {
            const response = await ApiService.post('bookings', data);

            return response.json();
        } catch (err) {
            return { error: err };
        }
    },

    deleteBooking: async function(id) {
        try {
            await ApiService.delete('bookings', id);
        } catch (err) {
            return { error: err };
        }
    }
}

export default BookingService;
export { BookingService };