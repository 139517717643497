const baseUrl = process.env.VUE_APP_API_BASE_URL;
const baseHeaders = {
    'Content-Type': 'application/json'
};

const ApiService = {
    get(resource) {
        return fetch(baseUrl + resource);
    },

    post(resource, data, headers = baseHeaders) {
        return fetch(baseUrl + resource, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data)
        });
    },

    put(resource, data, headers = baseHeaders) {
        return fetch(baseUrl + resource, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(data)
        })
    },

    delete(resource, id, headers = baseHeaders) {
        return fetch(baseUrl + resource + '/' + id, {
            method: 'DELETE',
            headers: headers
        })
    },
}

export default ApiService;