<template>
  <v-app>
    <v-app-bar
      app
      color="transparent"
      flat
      clipped-left
      class="mb-3 py-3"
      height="200px">
      <logo></logo>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import Logo from '@/components/Logo.vue';

export default {
  name: 'App',

  data: () => ({
    //
  }),

  components: {
    Logo,
  }
};
</script>

<style lang="scss">
.v-application {
  background: linear-gradient(to right, #29323c, #485563) !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  &:before {
    background-size: cover;
    background: url('./assets/bg4.jpg') fixed center;
    @media screen and (min-width: 769px) {
      background-size: cover;
    }
    content: " ";
    height: 100%;
    width: 100%;
    position: absolute;
    opacity: 0.5;
    filter: sepia(10%) blur(0);
  }
}
</style>
