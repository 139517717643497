import ApiService from './api.service';

const WorkplaceService = {
    getWorkplaces: async function() {
        try {
            const response = await ApiService.get('workplaces');

            return response.json();
        } catch (err) {
            return { error: err };
        }
    }
}

export default WorkplaceService;
export { WorkplaceService };