<template>
  <v-container>
    <div class="logo"></div>
  </v-container>
</template>

<script>
export default {
  components: {
  }
}
</script>

<style lang="scss" scoped>
  .logo {
    background-image: url('../assets/tischfluegel-logo.png');
    background-size: contain;
    background-position: center center;
    height: 150px;
    margin: 1rem 0 0 0;
  }
</style>