<template>
  <div>
    <v-container class="position-xs-bottom" v-if="!email">
      <!-- email card -->
      <v-card elevation="0" color="transparent" dark class="mt-15">
        <v-card-title>Ahoi Pirat*in!</v-card-title>
        <v-card-text>Trage hier deinen E-Mail-Namen ein und buche deinen <s>Arbeitsplatz</s> Platz auf einem unserer Piratenschiffe! Harr!</v-card-text>
        <v-card-text>
          <v-text-field v-model="emailName" outlined hide-details placeholder="Dein E-Mail-Name" suffix="@hot-sushi.com"></v-text-field>
          <v-checkbox v-model="saveEmail" label="im Browser speichern" hide-details></v-checkbox>
        </v-card-text>
      </v-card>
    </v-container>

    <v-container v-if="email">
      <v-card elevation="0" class="mb-5" color="transparent" dark>
        <v-card-title>Hi {{ emailName }}!</v-card-title>
        <v-card-text>Wähle einfach deinen Wunschtag, um in See zu stechen, und den <s>Arbeitsplatz</s> Piratenschiff-Platz, den du buchen möchtest.</v-card-text>
        <v-divider></v-divider>
        <v-card-text>Nicht {{ emailName }}? <v-btn text small elevation="0" color="primary" @click="handleChangeUserClick()">Benutzer ändern</v-btn></v-card-text>
      </v-card>

      <!-- booking card -->
      <v-card elevation="0" class="mt-n5" color="transparent">
        <v-card-text>
          <v-date-picker
            @change="handleChangeDateClick()"
            full-width
            v-model="bookForDate"
            :allowed-dates="disablePastDates"
            :first-day-of-week="1"
            elevation="10"
            dark
            class="glass"
          ></v-date-picker>
        </v-card-text>
        <v-card-text>
          <v-alert type="error" text v-if="error">Beim Klabautermann! Da war wohl ein*e Kamerad*in schneller! Wähle einen anderen Zeitpunkt, um in See zu stechen oder einen anderen Platz.</v-alert>
          <v-alert type="error" text v-if="apiError">Ach versenk mich doch – da muss wohl mal auf der API klar Schiff gemacht werden! Nicht den Klüver hängen, einfach nochmal versuchen.</v-alert>

          <v-autocomplete
            v-if="filteredWorkplaces.length > 0"
            v-model="selectedWorkplace"
            :items="filteredWorkplaces"
            item-text="label"
            item-value="@id"
            outlined
            placeholder="Wähle einen Arbeitsplatz"
            hide-details
            color="#FFFFFF"
            dark
          >
          </v-autocomplete>
          <v-alert v-if="!apiError && filteredWorkplaces.length === 0" text icon="mdi-alert-circle" type="warning">Da hat dich wohl jemand über die Planke geschickt ... Für dieses Datum steht leider kein Platz mehr zur Verfügung!</v-alert>
          <v-btn text small color="primary" @click.stop="dialogWorkplacePlan = !dialogWorkplacePlan">Landkarte ansehen</v-btn>
        </v-card-text>
      </v-card>

      <v-dialog v-model="dialogWorkplacePlan">
        <v-card v-if="$vuetify.breakpoint.xs">
          <v-img
              lazy-src="@/assets/raumplan-piraten-mobil.jpg"
              src="@/assets/raumplan-piraten-mobil.jpg"
          ></v-img>
        </v-card>

        <v-card v-else>
          <v-img
              lazy-src="@/assets/raumplan-piraten.jpg"
              src="@/assets/raumplan-piraten.jpg"
          ></v-img>
        </v-card>
      </v-dialog>

      <v-snackbar
        v-model="snackbar"
        color="success"
        top
      >
        <v-icon>mdi-check-circle</v-icon>
        Buchung erfolgreich – willkommen an Bord, Pirat*in!
      </v-snackbar>
    </v-container>

    <v-footer
        app
        fixed
        padless
        color="transparent"
        class="glass"
    >
      <v-card elevation="10" class="flex" tile color="rgba(0,0,0,0.1)">
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn icon to="/booking" v-if="email">
            <v-icon color="white">mdi-calendar</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              @click.stop="handleNextClick()"
              color="orange lighten-2"
              class="pl-5 rounded-pill white--text"
              elevation="0"
              :disabled="!emailName"
              v-if="!email"
          >
            Weiter
            <v-icon class="ml-3">mdi-arrow-right-circle</v-icon>
          </v-btn>
          <v-btn
              color="orange lighten-2"
              class="pl-5 rounded-pill white--text"
              elevation="0"
              :disabled="!selectedWorkplace"
              @click.stop="handleBookClick()"
              v-if="email"
          >
            Buchen
            <v-icon class="ml-3">mdi-check-circle</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-footer>
  </div>
</template>

<script>
import BookingService from '@/services/booking.service';
import WorkplaceService from '@/services/workplace.service';

export default {
  name: 'Home',

  components: {
  },

  data: () => ({
    emailName: null,
    email: null,
    saveEmail: false,
    bookForDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    selectedWorkplace: null,
    dialogWorkplacePlan: false,
    bookings: [],
    workplaces: [],
    filteredWorkplaces: [],
    snackbar: false,
    error: false,
    apiError: false,
  }),

  watch: {
    email: function() {
      this.getWorkplaces();
      this.getBookingsByDate();
    }
  },

  created() {
    this.getEmailAddressFromLocalStorage();
  },

  methods: {
    handleNextClick() {
      this.setEmailAddress();
    },

    handleChangeUserClick() {
      this.error = false;
      this.emailName = null;
      this.email = null;
      this.saveEmail = false;

      if (localStorage.getItem('email')) {
        localStorage.removeItem('email');
      }
    },

    handleChangeDateClick() {
      this.getBookingsByDate();
    },

    async handleBookClick() {
      this.error = false;

      const data = await BookingService.postBooking({
        email: this.email,
        bookedForDate: this.bookForDate,
        workplace: this.selectedWorkplace
      });

      if (data['bookedForDate']) {
        this.snackbar = true;
      } else {
        this.error = true;
      }

      await this.getBookingsByDate();
    },

    setEmailAddress() {
      this.email = this.emailName + '@hot-sushi.com';

      if (!this.saveEmail) {
        return false;
      }

      localStorage.setItem('email', this.email);
    },

    getEmailAddressFromLocalStorage() {
      this.email = localStorage.getItem('email') ? localStorage.getItem('email') : null;
      this.emailName = this.email ? this.email.split('@')[0] : null;
    },

    disablePastDates(val) {
      return val >= new Date().toISOString().substr(0, 10)
    },

    async getBookingsByDate() {
      this.apiError = false;
      this.error = false;
      this.selectedWorkplace = null;

      const data = await BookingService.getBookings(this.bookForDate, this.bookForDate);

      if (data.error) {
        this.apiError = true;

        return false;
      }

      this.bookings = data['hydra:member'];

      this.filterWorkplaces();
    },

    async getWorkplaces() {
      this.apiError = false;

      const data = await WorkplaceService.getWorkplaces();

      if (data.error) {
        this.apiError = true;

        return false;
      }

      this.workplaces = data['hydra:member'];
    },

    filterWorkplaces() {
      this.filteredWorkplaces = this.workplaces.filter(item => {
        return this.bookings.every(f => {
          return f.workplace.id !== item.id;
        })
      });
    }
  }
}
</script>

<style lang="scss">
.border-top {
  border-top: 1px solid #EEE;
}

.glass, .v-app-bar--is-scrolled {
  background-color: transparent !important;
  backdrop-filter: blur(5px);

  .theme--dark.v-picker__body {
    background-color: transparent !important;
  }
}

@media screen and (max-width: 767px) {
  .position-xs-bottom {
    position: absolute;
    bottom: 0;
  }
}
</style>
